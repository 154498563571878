import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const FooterContainer = styled('footer')(({ theme }) =>({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '40px 0',
    marginTop: 'auto',
}));

const StyledLink = styled('a')({
    textDecoration: 'none',
    color: (props) => props.theme.palette.primary.contrastText,
    margin:"15px",
    fontSize : "18px",
    '&:hover': {
        textDecoration: 'underline',
    },
});

const Footer = () => {
    
    const host_uri = "https://www.ociustechnologies.com"

    return (
        <FooterContainer>
          <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <img src="/Ocius-Logo.png" alt="Company Logo" 
                        style={{ width: '120px', marginBottom: '20px' }} />
                    <Typography variant="body1" gutterBottom>
                            Ocius Technologies
                        </Typography>
                        <Typography variant="body2">
                        Ocius Technologies is a group of passionate and experienced professionals who strive to provide innovative
                         products and reliable professional services to business enterprises worldwide. 
                         With a core competence in creating and deploying cost-effective capabilities using an offshore-centric business model, our team is committed to delivering tailored solutions that integrate seamlessly with your existing systems.
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Quick Links
                        </Typography>
                        <Typography variant="body2">
                            <StyledLink href="/">
                                Home
                            </StyledLink>
                        </Typography>
                        <Typography variant="body2">
                            <StyledLink href={`${host_uri}//about-us/`} >
                                About Us
                            </StyledLink>
                        </Typography>
                        <Typography variant="body2">
                            <StyledLink href={`${host_uri}/services/`} >
                                Services
                            </StyledLink>
                        </Typography>
                        <Typography variant="body2">
                            <StyledLink href={`${host_uri}/contact`}>
                                Contact Us
                            </StyledLink>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2">
                            Email: <a href="mailto:info@ociustechnologies.com" >info@ociustechnologies.com</a>
                        </Typography>
                        
                        <Typography variant="body2">
                            Address: 90 E Halsey rd, Suite 315, Parsippany, NJ 07054
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="body2" align="center" margin={4}>
                    &copy; {new Date().getFullYear()} Ocius Technologies. All rights reserved.
                </Typography>
            </Container>
        </FooterContainer>
    );
};

export default Footer;
