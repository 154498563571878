import React from 'react';
import { AppBar, Toolbar, Typography,Box } from '@mui/material';

const Header = () => {
    return (
        <AppBar position="static">
            <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <Box display="flex" alignItems="center">
                <img src="/Ocius-Logo.png" alt="Company Logo" style={{ width: 120, marginRight: 15, marginLeft:20 }}/>
                <Typography variant="h6" component="div" margin={1}>
                    Ocius Technologies
                </Typography>
            </Box>    
            </Toolbar>
        </AppBar>
    );
};

export default Header;