import ashtanga from './pose_gif/Ashtanga Namaskara.gif';
import childpose from  './pose_gif/Balasana.gif';
import catcow from  './pose_gif/Cat-Cow Stretch (Chakravakasana).gif';
// 'Childs_Pose_Stretch.gif', 
import cobbler from './pose_gif/cobbler.gif';
import cobra from  './pose_gif/cobra stretching.gif';
import corpse from  './pose_gif/Corpse Pose (Savasana).gif';
import staffpose from  './pose_gif/Dandasana (Staff pose).webp';
import facingdog from  './pose_gif/Downward-Dog-Adho-Mukha-.gif';
import easypose from './pose_gif/Easy Pose (Sukhasana).jpg';
import sideangle  from  './pose_gif/extended-side-angle pos.gif';
import triangle from  './pose_gif/ExtendedTriangleTrikonasana-.gif';
import glute_bridge  from './pose_gif/glute brige.gif';
import spinetwist from './pose_gif/Half Lord of the Fishes Pose (Ardha Matsyendrasana) .gif';
import babypose from  './pose_gif/HappyBabyAnandaBalasana-.gif';
import janupose from  './pose_gif/Head to Knee Pose (Janu Sirsasana).gif';
import jumping_jacks from  './pose_gif/jumping jacks.gif';
import lateral_raise from  './pose_gif/Lateral raise .gif';
import leg_raise_pose from  './pose_gif/leg raise.gif';
// import  'LeggedDogTriPadaAdhoMukhaSvanasana-.gif', 
import lowlunge from './pose_gif/LowLungeTwistPavrittaAnjaneyasana-.gif';
import lunge from  './pose_gif/lunge.gif';
import garland from './pose_gif/Malasana-Squats.gif';
import mountain from  './pose_gif/Mountain Pose (Tadasana).gif';
import threelegsplit from  './pose_gif/One-Legged Dog (Eka Pada Adho Mukha Svanasana).gif';
import plank from './pose_gif/plank.webp';
import push_up_pose from './pose_gif/push up .gif'; 
import pyramid from './pose_gif/PyramidPose-.gif';
//  'PyramidPose-.gif', 
import raisehand from './pose_gif/raise hand.gif';
import warrior3 from  './pose_gif/reverse warrior pose.gif'; 
import seatedforwardbend from './pose_gif/SeatedForwardBendPaschimottanasana-.gif';
//  'SeatedForwardFoldPaschimottanasana-.gif',
import forwardfold from   './pose_gif/Standing-Forward-Fold-Utanasana-`.gif';
import supinetwist from './pose_gif/Supine Spinal Twist (Supta Matsyendrasana).gif';
import tree from  './pose_gif/Tree pose.gif';
import v_up_pose from  './pose_gif/v up.gif';
import warrior2 from  './pose_gif/warrior_2.gif';
import warrior1 from  './pose_gif/warrior.gif';
import halfforwardbend from  './pose_gif/halfforwardfold.gif';
import squats from  './pose_gif/squat.webp';
import bicep from './pose_gif/bicep.webp';
import situp from './pose_gif/situp.gif';
import seatedwideangle from './pose_gif/seatedwideangle.jpg'
import wildthing from './pose_gif/wildthing.gif'
import wideanglestandingbend from './pose_gif/wideanglestandingbend.gif'
import wheelpose  from './pose_gif/wheelpose.webp'
import parivrttajanu from './pose_gif/parivrttajanu.gif'
import reclininghero from './pose_gif/reclinginhero.gif'
import halasana from './pose_gif/halasana.gif'
import hanuman from './pose_gif/hanuman.gif'
import dancepose from './pose_gif/dancepos.gif'
import salabhasana  from './pose_gif/salabhasana.webp'
import heron from './pose_gif/heron.gif'
import halfmoon from './pose_gif/halfmoon.gif'
import helffrog from './pose_gif/halfrog.gif'
import fish from './pose_gif/fihspose.webp'
import firefly from './pose_gif/firefly.gif'
import extendhandtoe from './pose_gif/extendhandtoe.gif'
import crowpose from './pose_gif/crowpose.gif'
import chairpose from './pose_gif/chairpose.webp'
import camelpose from './pose_gif/camelpose.gif'
import bowpose from './pose_gif/bowpose.gif'
import bharadvaja from './pose_gif/bharadvaja.gif'
import eaglepose from './pose_gif/eagle.gif'
import chaturanga from './pose_gif/chaturanga.webp'


export const all_pose_image = {
    'Glute Bridge': glute_bridge,
    'Cobra Stretching': cobra,
    'Leg Raise': leg_raise_pose,
    'Lateral Raise': lateral_raise,
    'V ups': v_up_pose,
    'Push ups': push_up_pose,
    'Jumping Jacks': jumping_jacks,
    'Lunge': lunge,
    'Sit up': situp,
    'Squats': squats,
    "Bicep": bicep,
    "Plank": plank,
    "Adho Mukha Svanasana (Downward Facing Dog)": facingdog,
    "Tadasana (Mountain Pose)": mountain,
    "Malasana (Garland Pose)": garland,
    "Ardha Uttanasana (Half Forward Bend)": halfforwardbend,
    "Low Lunge": lowlunge,
    "Parsvottanasana (Pyramid Pose)": pyramid,
    "Urdhva Hastasana (Raised Hands Pose)": raisehand,
    "Utthita Parvakonasana (Extended Side Angle)": sideangle,
    "Vrksasana (Tree Pose)": tree,
    "Utthita Trikonasana (Triangle Pose)": triangle,
    "Virabhadrasana I (Warrior I )": warrior1,
    "Virabhadrasana II (Warrior II)": warrior2,
    "Viparita Virabhadrasana (Reverse Warrior)": warrior3,
    "Uttanasana (Standing Forward Bend)": forwardfold,
    "Plank Pose" : plank,
    'Setu Bandha Sarvangasana (Bridge Pose)': glute_bridge,
    "Downward Facing Dog Split": threelegsplit,
    "Chakravakasana (Cat-Cow Stretch)": catcow,
    "Bhujangasana (Cobra Pose)": cobra,
    "Ashtanga Namaskara  (Knees, Chest, and Chin)": ashtanga,
    "Dandasana (Staff Pose)": staffpose,
    "Baddha Konasana (Cobbler's Pose)": cobbler,
    "Sukhasana (Easy Pose)": easypose,
    "Ardha Matsyendrasana (Half Lord of the Fishes Pose)": spinetwist,
    "Janu Sirsasana (Head to Knee Pose)": janupose,
    "Paschimottanasana (Seated Forward Bend)": seatedforwardbend,
    "Upavistha Konasana (Seated Wide Angle Straddle)": seatedwideangle,
    "Ananda Balasana (Happy Baby Pose)": babypose,
    "Supta Matsyendrasana (Supine Spinal Twist)": supinetwist,
    "Balasana (Child's Pose)": childpose,
    "Savasana (Corpse Pose)": corpse,

    "Bharadvaja’s Twist": bharadvaja,
    "Dhanurasana (Bow Pose)": bowpose,
    "Ustrasana (Camel Pose": camelpose,
    "Utkatasana (Chair Pose)": chairpose,
    "Bakasana (Crow Pose)": crowpose,
    "Garudasana (Eagle Pose)": eaglepose,
    "Utthita Hasta Padangustasana (Extended Hand-to-Big-Toe Pose": extendhandtoe,
    "Tittibhasana (Firefly Pose)": firefly,
    "Matsyasana (Fish Pose)": fish,
    "Chaturanga Dandasana (Four-Limbed Staff Pose": chaturanga,
    "Ardha Bhekasana (Half Frog Pose)": helffrog,
    "Ardha Chandrasana (Half Moon Pose)": halfmoon,
    "Krounchasana (Heron Pose)": heron,
    "Salabhasana (Locust Pose)": salabhasana,
    "Natarajasana (Lord of the Dance Pose)": dancepose,
    "Hanumanasana (Monkey Pose)": hanuman,
    "Halasana (Plow Pose)": halasana,
    "Supta Virasana (Reclining Hero Pose)": reclininghero,
    "Parivrtta Janu Sirsasana (Revolved Head-to-Knee Pose)": parivrttajanu,
    "Urdhva Dhanurasana (Wheel Pose)": wheelpose,
    "Prasarita Padottanasana (Wide-Legged Standing Forward Bend)": wideanglestandingbend,
    "Camatkarasana (Wild Thing)": wildthing


}
