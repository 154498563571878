import React from 'react';
import { Card, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Define your custom styled components with modified styles
const StyledCard = styled(Card)({
  maxWidth: '100%',  // Adjusts the card to be as wide as its container
  margin: 'auto',    // Centers the card horizontally
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '70vh', /* Increase height for mobile devices */
  padding: '20px',
  '@media (max-width: 768px)': {
}
});

const StyledCardMedia = styled(CardMedia)({
  height: '100%',   // Ensures the image height adjusts proportionally
  width: '100%',    // Makes the image take full width of the card
  objectFit: 'contain',  // Fits the entire image inside the CardMedia
});

const FrameViewer = ({ frame }) => {
  return (
    <StyledCard>
      <StyledCardMedia
        component="img"
        image={frame}
        alt="Frame"
      />
    </StyledCard>
  );
};

export const ImageViewer = ({ frame, text }) => {
  return (
    <StyledCard>
      <StyledCardMedia
        component="img"
        image={frame}
        alt="Frame"
      />
      <Typography>
        {text}
      </Typography>
      <p style={{fontSize: 12,
                 fontFamily: 'sans-serif',
                 fontStyle: 'italic'}}>
                  "Special thanks to the creators of the internet images."</p>
    </StyledCard>
  );
};





export default FrameViewer;
