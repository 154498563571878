import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 12,
  p: 4,
  borderRadius: 5
};

export default function BasicModal({next_poses, setcurrentPose}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const button_hander = (pose_name)=>{
    if (pose_name == "Ashtanga Namaskara (Knees Chest and Chin)"){
        setcurrentPose("Ashtanga Namaskara  (Knees, Chest, and Chin)")
        handleClose()
        return
    }
    setcurrentPose(pose_name)
    handleClose()
  }
  React.useEffect(()=>{
       console.log(next_poses)
       if (next_poses && next_poses[0]){
        handleOpen()
       }
  }, [next_poses])
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

            {/* {console.log(next_poses)} */}
       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        <Button variant="outlined" color="secondary" onClick={(e)=> handleClose()}>
          Close
        </Button>
         </Box>
        {next_poses[0] ? (<Typography variant='h5' align='center'>
            Do Your next Pose 
         </Typography>): (
            <Typography variant='h5' align='center'>
            Not any next Pose for you !
         </Typography>
         )} 
        {next_poses[0] && 
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button variant="contained" color="primary" sx={{ mr: 1 , fontSize:8}}
        onClick={(e)=> button_hander(next_poses[0])}
        >
        {next_poses[0]}
        </Button>
        <Button variant="contained" color="primary" sx={{ ml: 1, fontSize:8 }}
        onClick={(e)=> button_hander(next_poses[1])}>
        {next_poses[1]}
        </Button>
         </Box>
         }
         

        </Box>

      </Modal>
    </div>
  );
}
