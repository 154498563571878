import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles'; // Corrected import for styled

// Styled CircularProgress component to include a background circle
const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round',
  },
}));

const CircularTimer = ({ targetTime }) => {
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(targetTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime(targetTime));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  function calculateRemainingTime(targetTime) {
    const now = new Date();
    const target = new Date(targetTime);
    const timeDiff = target - now;

    if (timeDiff <= 0) {
      return { totalSeconds: 0 };
    }

    const totalSeconds = Math.floor(timeDiff / 1000);
    return { totalSeconds };
  }

  const { totalSeconds } = remainingTime;
  const progress = (totalSeconds / (60 * 2)) * 100;

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 100,
        height: 100,
        position: 'relative',
      }}
    >
      <StyledCircularProgress
        variant="determinate"
        value={progress}
        size={75}
        thickness={4}
        sx={{ color: '#76c7c0' }}
      />
      <Typography
        variant="h6"
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        {`${String(Math.floor(totalSeconds / 60)).padStart(2, '0')}:${String(totalSeconds % 60).padStart(2, '0')}`}
      </Typography>
    </Paper>
  );
};

export default CircularTimer;
