const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendWsUrl = process.env.REACT_APP_BACKEND_WS_URL;

export const getAllpose = ()=>{
    return fetch(`${backendUrl}/posenames`, {
        method: 'GET',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        
    }).then(response => (response.json()))
      .catch(err => console.log(err))
}

export const getAllchoices = ()=>{
    return fetch(`${backendUrl}/choices`, {
        method: 'GET',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        
    }).then(response => (response.json()))
      .catch(err => console.log(err))
}

export const generateclass = (data)=>{
    return fetch(`${backendUrl}/make_class`, {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        
    }).then(response => (response.json()))
      .catch(err => console.log(err))
}



export const getnextpose = (data)=>{
    return fetch(`${backendUrl}/nextpose`, {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        
    }).then(response => (response.json()))
      .catch(err => console.log(err))
}