import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VideoDisplay from './component/VideoDisplay';
import Header from './component/Header';
import Footer from './component/Footer';
const theme = createTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header/>
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <VideoDisplay />
      </Container>
      <Footer/>
    </ThemeProvider>
  );
};

export default App;
