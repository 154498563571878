export function writeToLocalStorage(key, value) {
    
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.error('Error saving to LocalStorage:', e);
    }
}

export function readFromLocalStorage(key) {
    
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    } catch (e) {
        console.error('Error reading from LocalStorage:', e);
        return null;
    }
}
